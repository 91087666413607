<template>
    <div class="home-logged-in">
        <Header @logout="logout" v-if="!isInGame"></Header>

        <div class="views">
            <router-view class="view" />
        </div>
        
        <Footer v-if="!isInGame"></Footer>
        <Banner :visible="banner.status"/>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    components: { 
        Header: () => import('@/components/utils/Header'),
        // GuestHeader: () => import('@/components/landing-page/GuestHeader'),
        Banner: () => import('@/components/utils/Banner'),
        Footer: () => import('@/components/utils/Footer')
    },

    computed: {
        ...mapFields(['user', 'banner']),

        isInGame () {
            return this.$route.name === 'card-assessment' || this.$route.name === 'card-summary' ||
                    this.$route.name === 'result-confirmation' || this.$route.name === 'assessment-result'
        }
	},

    methods: {
        logout () {
            this.$store.dispatch('logout')
        }
    },
}
</script>

<style lang="scss" scoped>
.home-logged-in {
    width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

    // background-image: url('~@/assets/img/home_upperbg.png');
    // background-repeat: no-repeat;
    // background-size: 100%;

    .views {
		flex: 1 1 auto;
		overflow: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

        .view {
            @include desktop {
                height: 100vh;
            }
        }
	}
}
</style>